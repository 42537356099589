import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: Date;
  Map: { [key: string]: any };
};

export type DataPoint = {
  __typename?: 'DataPoint';
  x: Scalars['Time'];
  y: Scalars['Float'];
};

export type Hmi = {
  __typename?: 'HMI';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Request = {
  __typename?: 'Request';
  id: Scalars['ID'];
  name: Scalars['String'];
  client: Client;
  sensor: Sensor;
  functionCode: Scalars['Int'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type NewSensorTypeVariable = {
  typeId: Scalars['Int'];
  name: Scalars['String'];
  address: Scalars['Int'];
  length: Scalars['Int'];
  numericType: Scalars['Int'];
  byteOrder: Scalars['Int'];
  conversionFactor: Scalars['Float'];
  functionCode: Scalars['Int'];
  valueTypeId?: Maybe<Scalars['Int']>;
};

export type UpdateUser = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export type NewClient = {
  name: Scalars['String'];
};

export type Agent = {
  __typename?: 'Agent';
  id: Scalars['ID'];
  client: Client;
  externalId?: Maybe<Scalars['String']>;
  totalActiveAlarms: Scalars['Int'];
  alarms: Array<PlatformAlarm>;
  protocol: Scalars['Int'];
  subProtocol: Scalars['Int'];
  protocolIdentifier: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  lastHeartbeat?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  online: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  totalSensors: Scalars['Int'];
  sensors: Array<Sensor>;
};

export type DashboardChart = {
  __typename?: 'DashboardChart';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DashboardChartType;
  chart: DashboardChartResult;
};

export type DashboardChartChartArgs = {
  filter?: Maybe<ChartFilter>;
};

export type GraphVariable = {
  __typename?: 'GraphVariable';
  name: Scalars['String'];
  data: Array<DataPoint>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
};

export type NewSensor = {
  name: Scalars['String'];
  description: Scalars['String'];
  agentId: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
  sensorTypeId: Scalars['Int'];
  externalId: Scalars['String'];
  slave: Scalars['Int'];
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  client: Client;
  sensorVariables: Array<SensorVariable>;
  externalId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  client: Client;
  name: Scalars['String'];
  tagGroup: TagGroup;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type UserQueryFilter = {
  searchText: Scalars['String'];
};

export type DashboardChartResult = Chart | Hmi | InstantChart;

export type NewSensorVariable = {
  name: Scalars['String'];
  sensorId: Scalars['String'];
  sensorTypeId: Scalars['Int'];
  sensorTypeVariableId: Scalars['Int'];
  overriddenValueTypeId?: Maybe<Scalars['Int']>;
  overriddenConversionFactor?: Maybe<Scalars['Float']>;
};

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  client: Client;
  subscribers: Array<Subscriber>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type Pipeline = {
  __typename?: 'Pipeline';
  id: Scalars['ID'];
  name: Scalars['String'];
  request: Request;
  sensor: Sensor;
  steps: Array<PipelineStep>;
  sensorVariable: SensorVariable;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export enum AlarmType {
  Offline = 'Offline',
  ValueCheck = 'ValueCheck',
}

export type SensorValueType = {
  __typename?: 'SensorValueType';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type SensorListQueryResult = {
  __typename?: 'SensorListQueryResult';
  sensors: Array<Sensor>;
  pageInfo: PageInfo;
};

export type Session = {
  __typename?: 'Session';
  user: User;
  client: Client;
  role: UserRole;
};

export type NewUser = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
};

export type MeasureQueryResult = {
  __typename?: 'MeasureQueryResult';
  measures: Array<Measure>;
  pageInfo: PageInfo;
};

export type MeasureQueryParams = {
  sensorId: Scalars['String'];
  sensorVariableId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
};

export type UpdatePipelineStep = {
  name: Scalars['String'];
  description: Scalars['String'];
  configuration: Scalars['Map'];
  internal: Scalars['Boolean'];
  valueTypeId?: Maybe<Scalars['Int']>;
};

export type TagGroup = {
  __typename?: 'TagGroup';
  name: Scalars['String'];
  tags: Array<Tag>;
};

export enum DashboardChartType {
  Chart = 'CHART',
  Hmi = 'HMI',
  Instant = 'INSTANT',
}

export type DisplaySettings = {
  __typename?: 'DisplaySettings';
  title: Scalars['String'];
  type: ChartType;
  yAxis: AxisSettings;
  xAxis: AxisSettings;
};

export type ChartVariableConfig = {
  __typename?: 'ChartVariableConfig';
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Float']>;
};

export type Measure = {
  __typename?: 'Measure';
  id: Scalars['ID'];
  agent: Agent;
  client: Client;
  requestedTime: Scalars['Time'];
  dateTime: Scalars['Time'];
  sensor: Sensor;
  variable: SensorVariable;
  variableType: SensorTypeVariable;
  valueType?: Maybe<SensorValueType>;
  value: Scalars['Float'];
  rawValue: Scalars['Float'];
  conversionFactor: Scalars['Float'];
  hexValue: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAgent: Agent;
  updateAgent: Agent;
  createClientUser: ClientUser;
  scheduleMeasureReprocessing: Scalars['Boolean'];
  createNotificationGroup: NotificationGroup;
  updateNotificationGroup: NotificationGroup;
  addAgenToNotificationGroup: NotificationGroup;
  addSensorToNotificationGroup: NotificationGroup;
  createPipelineStep: PipelineStep;
  updatePipelineStep: PipelineStep;
  createPipeline: Pipeline;
  updatePipeline: Pipeline;
  createRequest: Request;
  updateRequest: Request;
  createRequestVariable: RequestVariable;
  createScheduledRequest: ScheduledRequest;
  updateScheduledRequest: ScheduledRequest;
  createSensorTypeVariable: SensorTypeVariable;
  updateSensorTypeVariable: SensorTypeVariable;
  createSensorType: SensorType;
  updateSensorType: SensorType;
  deleteSensorType: SensorType;
  createSensorValueType: SensorValueType;
  updateSensorValueType: SensorValueType;
  deleteSensorValueType: SensorValueType;
  createSensorVariable: SensorVariable;
  updateSensorVariable: SensorVariable;
  createSensor: Sensor;
  updateSensor: Sensor;
  createService: Service;
  updateService: Service;
  addSensorVariableToService: SensorVariable;
  removeSensorVariableFromService: Scalars['Boolean'];
  createSubscriber: Subscriber;
  updateSubscriber: Subscriber;
  createTag: Tag;
  updateTag: Tag;
  createUser: User;
  updateUser: User;
  updateUserPassword: User;
  updateUserInfo: User;
};

export type MutationCreateAgentArgs = {
  input: NewAgent;
};

export type MutationUpdateAgentArgs = {
  id: Scalars['ID'];
  input: NewAgent;
};

export type MutationCreateClientUserArgs = {
  input: NewClientUser;
};

export type MutationScheduleMeasureReprocessingArgs = {
  options: MeasureReprocessingOptions;
};

export type MutationCreateNotificationGroupArgs = {
  name: Scalars['String'];
};

export type MutationUpdateNotificationGroupArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationAddAgenToNotificationGroupArgs = {
  notificationGroupID: Scalars['Int'];
  agentID: Scalars['String'];
};

export type MutationAddSensorToNotificationGroupArgs = {
  notificationGroupID: Scalars['Int'];
  sensorID: Scalars['String'];
};

export type MutationCreatePipelineStepArgs = {
  input?: Maybe<NewPipelineStep>;
};

export type MutationUpdatePipelineStepArgs = {
  id: Scalars['ID'];
  input: UpdatePipelineStep;
};

export type MutationCreatePipelineArgs = {
  input: NewPipeline;
};

export type MutationUpdatePipelineArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  requestId: Scalars['String'];
};

export type MutationCreateRequestArgs = {
  input: NewRequest;
};

export type MutationUpdateRequestArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreateRequestVariableArgs = {
  input: NewRequestVariable;
};

export type MutationCreateScheduledRequestArgs = {
  input: NewScheduledRequest;
};

export type MutationUpdateScheduledRequestArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  interval: Scalars['Int'];
};

export type MutationCreateSensorTypeVariableArgs = {
  input: NewSensorTypeVariable;
};

export type MutationUpdateSensorTypeVariableArgs = {
  id: Scalars['ID'];
  input: NewSensorTypeVariable;
};

export type MutationCreateSensorTypeArgs = {
  input: NewSensorType;
};

export type MutationUpdateSensorTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationDeleteSensorTypeArgs = {
  id: Scalars['ID'];
};

export type MutationCreateSensorValueTypeArgs = {
  input: NewSensorValueType;
};

export type MutationUpdateSensorValueTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type MutationDeleteSensorValueTypeArgs = {
  id: Scalars['ID'];
};

export type MutationCreateSensorVariableArgs = {
  input: NewSensorVariable;
};

export type MutationUpdateSensorVariableArgs = {
  id: Scalars['ID'];
  input: NewSensorVariable;
};

export type MutationCreateSensorArgs = {
  input: NewSensor;
};

export type MutationUpdateSensorArgs = {
  id: Scalars['ID'];
  input: NewSensor;
};

export type MutationCreateServiceArgs = {
  input: NewService;
};

export type MutationUpdateServiceArgs = {
  id: Scalars['ID'];
  input: NewService;
};

export type MutationAddSensorVariableToServiceArgs = {
  serviceId: Scalars['ID'];
  sensorVariableId: Scalars['String'];
};

export type MutationRemoveSensorVariableFromServiceArgs = {
  serviceId: Scalars['ID'];
  sensorVariableId: Scalars['String'];
};

export type MutationCreateSubscriberArgs = {
  input: NewSubscriber;
};

export type MutationUpdateSubscriberArgs = {
  id: Scalars['ID'];
  input: NewSubscriber;
};

export type MutationCreateTagArgs = {
  name: Scalars['String'];
  groupID: Scalars['Int'];
};

export type MutationUpdateTagArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreateUserArgs = {
  input: NewUser;
};

export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  input: UpdateUser;
};

export type MutationUpdateUserPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInfo;
};

export type ScheduledRequest = {
  __typename?: 'ScheduledRequest';
  id: Scalars['ID'];
  name: Scalars['String'];
  request: Request;
  interval: Scalars['Int'];
  lastExecution: Scalars['Time'];
  nextExecution: Scalars['Time'];
  retries: Scalars['Int'];
  lastStatus: Scalars['Int'];
};

export type NewSensorType = {
  name: Scalars['String'];
};

export enum ChartType {
  Line = 'LINE',
  Bar = 'BAR',
}

export type ChartFilter = {
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
};

export type MeasureReprocessingOptions = {
  sensorId: Scalars['ID'];
  sensorVariableId: Scalars['ID'];
};

export type PipelineStep = {
  __typename?: 'PipelineStep';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['Int'];
  description: Scalars['String'];
  configuration: Scalars['Map'];
  internal: Scalars['Boolean'];
  pipeline: Pipeline;
  valueType: SensorValueType;
  nextStep?: Maybe<PipelineStep>;
  previousStep?: Maybe<PipelineStep>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type PlatformAlarm = {
  __typename?: 'PlatformAlarm';
  id: Scalars['ID'];
  type: AlarmType;
  status: Scalars['Int'];
  sensorVariable?: Maybe<SensorVariable>;
  pipelineStep?: Maybe<PipelineStep>;
  startTime: Scalars['Time'];
  restoringTime?: Maybe<Scalars['Time']>;
  finalizedTime?: Maybe<Scalars['Time']>;
  lastChecked: Scalars['Time'];
  counter: Scalars['Int'];
  message: Scalars['String'];
};

export type NewScheduledRequest = {
  name: Scalars['String'];
  requestId: Scalars['String'];
  interval: Scalars['Int'];
};

export type UpdateUserInfo = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type ChartVariable = {
  __typename?: 'ChartVariable';
  variable: SensorVariable;
  config?: Maybe<ChartVariableConfig>;
  data: Array<DataPoint>;
};

export type Query = {
  __typename?: 'Query';
  _version: Scalars['String'];
  agents: Array<Agent>;
  agent: Agent;
  sensorVariableChart: Chart;
  clientUsers: Array<ClientUser>;
  clientUser: ClientUser;
  clients: Array<Client>;
  dashboards: Array<Dashboard>;
  dashboardChart: DashboardChart;
  dashboardChartList: Array<DashboardChart>;
  hmiVariables: Array<HmiVariable>;
  hmiChart: Array<HmiVariable>;
  hmiGraph: Array<GraphVariable>;
  measures: MeasureQueryResult;
  measure: Measure;
  notificationGroups: Array<NotificationGroup>;
  notificationGroup: NotificationGroup;
  pipelineSteps: Array<PipelineStep>;
  pipelineStep: PipelineStep;
  pipelines: Array<Pipeline>;
  pipeline: Pipeline;
  requests: Array<Request>;
  request: Request;
  requestVariables: Array<RequestVariable>;
  requestVariable: RequestVariable;
  scheduledRequests: Array<ScheduledRequest>;
  scheduledRequest: ScheduledRequest;
  sensorTypeVariables: Array<SensorTypeVariable>;
  sensorTypeVariable: SensorTypeVariable;
  sensorTypes: Array<SensorType>;
  sensorType: SensorType;
  sensorValueTypes: Array<SensorValueType>;
  sensorValueType: SensorValueType;
  sensorVariable: SensorVariable;
  sensorList: SensorListQueryResult;
  sensor: Sensor;
  services: Array<Service>;
  service: Service;
  session: Session;
  subscribers: Array<Subscriber>;
  subscriber: Subscriber;
  tags: Array<Tag>;
  tag: Tag;
  tagGroups: Array<TagGroup>;
  users: Array<User>;
  user: User;
};

export type QueryAgentsArgs = {
  filter?: Maybe<AgentQueryFilter>;
};

export type QueryAgentArgs = {
  id: Scalars['ID'];
};

export type QuerySensorVariableChartArgs = {
  options: SensorChartOptions;
};

export type QueryClientUsersArgs = {
  clientId: Scalars['ID'];
};

export type QueryClientUserArgs = {
  id: Scalars['ID'];
};

export type QueryDashboardChartArgs = {
  id: Scalars['ID'];
};

export type QueryDashboardChartListArgs = {
  id: Scalars['ID'];
};

export type QueryHmiVariablesArgs = {
  id: Scalars['Int'];
};

export type QueryHmiChartArgs = {
  id: Scalars['ID'];
};

export type QueryHmiGraphArgs = {
  id: Scalars['Int'];
  subId: Scalars['Int'];
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
};

export type QueryMeasuresArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  input: MeasureQueryParams;
};

export type QueryMeasureArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationGroupsArgs = {
  clientID: Scalars['ID'];
};

export type QueryNotificationGroupArgs = {
  id: Scalars['ID'];
};

export type QueryPipelineStepsArgs = {
  pipelineId: Scalars['ID'];
};

export type QueryPipelineStepArgs = {
  id: Scalars['ID'];
};

export type QueryPipelinesArgs = {
  sensorVariableId: Scalars['String'];
};

export type QueryPipelineArgs = {
  id: Scalars['ID'];
};

export type QueryRequestsArgs = {
  sensorId: Scalars['ID'];
};

export type QueryRequestArgs = {
  id: Scalars['ID'];
};

export type QueryRequestVariablesArgs = {
  requestId: Scalars['ID'];
};

export type QueryRequestVariableArgs = {
  id: Scalars['ID'];
};

export type QueryScheduledRequestsArgs = {
  requestId: Scalars['String'];
};

export type QueryScheduledRequestArgs = {
  id: Scalars['ID'];
};

export type QuerySensorTypeVariablesArgs = {
  sensorTypeID: Scalars['ID'];
};

export type QuerySensorTypeVariableArgs = {
  id: Scalars['ID'];
};

export type QuerySensorTypeArgs = {
  id: Scalars['ID'];
};

export type QuerySensorValueTypeArgs = {
  id: Scalars['ID'];
};

export type QuerySensorVariableArgs = {
  id: Scalars['ID'];
};

export type QuerySensorListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<SensorQueryFilter>;
};

export type QuerySensorArgs = {
  id: Scalars['ID'];
};

export type QueryServiceArgs = {
  id: Scalars['ID'];
};

export type QuerySubscribersArgs = {
  notificationGroupId: Scalars['ID'];
};

export type QuerySubscriberArgs = {
  id: Scalars['ID'];
};

export type QueryTagsArgs = {
  filter: TagsFilter;
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  filter?: Maybe<UserQueryFilter>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type NewPipeline = {
  name: Scalars['String'];
  requestId: Scalars['String'];
  sensorId: Scalars['String'];
  sensorVariableId: Scalars['String'];
};

export type NewSensorValueType = {
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type NewSubscriber = {
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  notificationGroupId: Scalars['Int'];
};

export type NewAgent = {
  externalId: Scalars['String'];
  protocol: Scalars['Int'];
  subProtocol: Scalars['Int'];
  protocolIdentifier: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type Chart = {
  __typename?: 'Chart';
  variables: Array<ChartVariable>;
  displaySettings: DisplaySettings;
};

export type SensorTypeVariable = {
  __typename?: 'SensorTypeVariable';
  id: Scalars['ID'];
  type: SensorType;
  name: Scalars['String'];
  address: Scalars['Int'];
  length: Scalars['Int'];
  numericType: Scalars['Int'];
  byteOrder: Scalars['Int'];
  conversionFactor: Scalars['Float'];
  functionCode: Scalars['Int'];
  valueType?: Maybe<SensorValueType>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type Subscriber = {
  __typename?: 'Subscriber';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  notificationGroupId: Scalars['Int'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export enum UserRole {
  User = 'USER',
  ClientAdmin = 'CLIENT_ADMIN',
  MasterAdmin = 'MASTER_ADMIN',
}

export type SensorType = {
  __typename?: 'SensorType';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  variables: Array<SensorTypeVariable>;
};

export type SensorVariable = {
  __typename?: 'SensorVariable';
  id: Scalars['ID'];
  name: Scalars['String'];
  sensor: Sensor;
  lastMeasure?: Maybe<Measure>;
  sensorType: SensorType;
  sensorTypeVariable: SensorTypeVariable;
  valueType?: Maybe<SensorValueType>;
  overriddenValueType?: Maybe<SensorValueType>;
  overriddenConversionFactor?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type SensorQueryFilter = {
  name: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  name: Scalars['String'];
  alias: Scalars['String'];
};

export type AgentQueryFilter = {
  name: Scalars['String'];
};

export type SensorChartOptions = {
  sensorId: Scalars['ID'];
  variableId: Scalars['ID'];
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
};

export type InstantChartVariable = {
  __typename?: 'InstantChartVariable';
  variable: SensorVariable;
  value: Scalars['Float'];
  config?: Maybe<ChartVariableConfig>;
};

export type NewRequest = {
  name: Scalars['String'];
  sensorId: Scalars['String'];
  functionCode: Scalars['Int'];
};

export type Sensor = {
  __typename?: 'Sensor';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  totalActiveAlarms: Scalars['Int'];
  alarms: Array<PlatformAlarm>;
  agent: Agent;
  client: Client;
  service?: Maybe<Service>;
  online: Scalars['Boolean'];
  sensorType: SensorType;
  variables: Array<SensorVariable>;
  requests: Array<Request>;
  externalId: Scalars['String'];
  slave: Scalars['Int'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export type NewClientUser = {
  clientId: Scalars['String'];
  userId: Scalars['String'];
  role: Scalars['String'];
};

export type NewPipelineStep = {
  name: Scalars['String'];
  type: Scalars['Int'];
  description: Scalars['String'];
  configuration: Scalars['Map'];
  internal: Scalars['Boolean'];
  pipelineId: Scalars['Int'];
  valueTypeId?: Maybe<Scalars['Int']>;
  nextStepId?: Maybe<Scalars['Int']>;
  previousStepId?: Maybe<Scalars['Int']>;
};

export type NewRequestVariable = {
  requestId: Scalars['String'];
  sensorVariableID: Scalars['String'];
};

export type NewService = {
  name: Scalars['String'];
  description: Scalars['String'];
  externalId: Scalars['String'];
};

export type ClientUser = {
  __typename?: 'ClientUser';
  id: Scalars['ID'];
  client: Client;
  user: User;
  role: Scalars['String'];
};

export type HmiVariable = {
  __typename?: 'HMIVariable';
  name: Scalars['String'];
  shortName: Scalars['String'];
  value: Scalars['Float'];
  top: Scalars['String'];
  left: Scalars['String'];
};

export type TagsFilter = {
  name?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  assignedClients: Array<ClientUser>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AxisSettings = {
  __typename?: 'AxisSettings';
  title: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InstantChart = {
  __typename?: 'InstantChart';
  variables: Array<InstantChartVariable>;
  displaySettings: DisplaySettings;
};

export type RequestVariable = {
  __typename?: 'RequestVariable';
  id: Scalars['ID'];
  request: Request;
  sensorVariable: SensorVariable;
};

export type AgentFieldsFragment = { __typename?: 'Agent' } & Pick<
  Agent,
  | 'id'
  | 'name'
  | 'description'
  | 'lastHeartbeat'
  | 'externalId'
  | 'online'
  | 'createdAt'
  | 'protocolIdentifier'
  | 'latitude'
  | 'longitude'
  | 'totalActiveAlarms'
  | 'totalSensors'
>;

export type AgentsQueryVariables = Exact<{ [key: string]: never }>;

export type AgentsQuery = { __typename?: 'Query' } & {
  agents: Array<{ __typename?: 'Agent' } & AgentFieldsFragment>;
};

export type AgentQueryVariables = Exact<{
  agentId: Scalars['ID'];
}>;

export type AgentQuery = { __typename?: 'Query' } & {
  agent: { __typename?: 'Agent' } & Pick<Agent, 'protocol' | 'subProtocol'> &
    AgentFieldsFragment;
};

export type AgentsListQueryVariables = Exact<{
  agentName: Scalars['String'];
}>;

export type AgentsListQuery = { __typename?: 'Query' } & {
  agents: Array<{ __typename?: 'Agent' } & AgentFieldsFragment>;
};

export type AgentsWithSensorsQueryVariables = Exact<{
  agentName: Scalars['String'];
}>;

export type AgentsWithSensorsQuery = { __typename?: 'Query' } & {
  agents: Array<
    { __typename?: 'Agent' } & {
      sensors: Array<{ __typename?: 'Sensor' } & Pick<Sensor, 'id' | 'online'>>;
    } & AgentFieldsFragment
  >;
};

export type ChartVariableFieldsFragment = { __typename?: 'ChartVariable' } & {
  variable: { __typename?: 'SensorVariable' } & Pick<SensorVariable, 'name'>;
  data: Array<{ __typename?: 'DataPoint' } & Pick<DataPoint, 'x' | 'y'>>;
  config?: Maybe<
    { __typename?: 'ChartVariableConfig' } & Pick<ChartVariableConfig, 'color'>
  >;
};

export type DisplaySettingsFieldsFragment = {
  __typename?: 'DisplaySettings';
} & Pick<DisplaySettings, 'type' | 'title'> & {
    yAxis: { __typename?: 'AxisSettings' } & Pick<AxisSettings, 'title'>;
    xAxis: { __typename?: 'AxisSettings' } & Pick<AxisSettings, 'title'>;
  };

export type VariableChartQueryVariables = Exact<{
  sensorId: Scalars['ID'];
  variableId: Scalars['ID'];
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
}>;

export type VariableChartQuery = { __typename?: 'Query' } & {
  sensorVariableChart: { __typename?: 'Chart' } & {
    variables: Array<
      { __typename?: 'ChartVariable' } & ChartVariableFieldsFragment
    >;
    displaySettings: {
      __typename?: 'DisplaySettings';
    } & DisplaySettingsFieldsFragment;
  };
};

export type DashboardFieldsFragment = { __typename?: 'Dashboard' } & Pick<
  Dashboard,
  'id' | 'name'
>;

export type DashboardQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardQuery = { __typename?: 'Query' } & {
  dashboards: Array<{ __typename?: 'Dashboard' } & DashboardFieldsFragment>;
};

export type ChartFieldsFragment = { __typename?: 'Chart' } & {
  variables: Array<
    { __typename?: 'ChartVariable' } & ChartVariableFieldsFragment
  >;
  displaySettings: {
    __typename?: 'DisplaySettings';
  } & DisplaySettingsFieldsFragment;
};

export type HmiFieldsFragment = { __typename?: 'HMI' } & Pick<
  Hmi,
  'id' | 'name'
>;

export type DashboardChartFieldsFragment = {
  __typename?: 'DashboardChart';
} & Pick<DashboardChart, 'id' | 'type' | 'name'> & {
    chart:
      | ({ __typename?: 'Chart' } & ChartFieldsFragment)
      | ({ __typename?: 'HMI' } & HmiFieldsFragment)
      | ({ __typename?: 'InstantChart' } & InstantChartFieldsFragment);
  };

export type DashboardChartListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DashboardChartListQuery = { __typename?: 'Query' } & {
  dashboardChartList: Array<
    { __typename?: 'DashboardChart' } & Pick<
      DashboardChart,
      'id' | 'type' | 'name'
    >
  >;
};

export type DashboardChartQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: Maybe<ChartFilter>;
}>;

export type DashboardChartQuery = { __typename?: 'Query' } & {
  dashboardChart: { __typename?: 'DashboardChart' } & Pick<
    DashboardChart,
    'id' | 'type' | 'name'
  > & {
      chart:
        | ({ __typename?: 'Chart' } & ChartFieldsFragment)
        | ({ __typename?: 'HMI' } & HmiFieldsFragment)
        | ({ __typename?: 'InstantChart' } & InstantChartFieldsFragment);
    };
};

export type HmiVariablesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type HmiVariablesQuery = { __typename?: 'Query' } & {
  hmiVariables: Array<
    { __typename?: 'HMIVariable' } & Pick<
      HmiVariable,
      'name' | 'shortName' | 'top' | 'left' | 'value'
    >
  >;
};

export type HmiGraphQueryVariables = Exact<{
  id: Scalars['Int'];
  subId: Scalars['Int'];
  startDate?: Maybe<Scalars['Time']>;
  endDate?: Maybe<Scalars['Time']>;
}>;

export type HmiGraphQuery = { __typename?: 'Query' } & {
  hmiGraph: Array<
    { __typename?: 'GraphVariable' } & Pick<GraphVariable, 'name'> & {
        data: Array<{ __typename?: 'DataPoint' } & Pick<DataPoint, 'x' | 'y'>>;
      }
  >;
};

export type InstantChartFieldsFragment = { __typename?: 'InstantChart' } & {
  variables: Array<
    { __typename?: 'InstantChartVariable' } & InstantChartVariableFieldsFragment
  >;
  displaySettings: {
    __typename?: 'DisplaySettings';
  } & DisplaySettingsFieldsFragment;
};

export type InstantChartVariableFieldsFragment = {
  __typename?: 'InstantChartVariable';
} & Pick<InstantChartVariable, 'value'> & {
    config?: Maybe<
      { __typename?: 'ChartVariableConfig' } & Pick<ChartVariableConfig, 'name'>
    >;
  };

export type SensorTypeListFieldsFragment = { __typename?: 'SensorType' } & Pick<
  SensorType,
  'id' | 'name' | 'createdAt' | 'updatedAt'
>;

export type SensorTypeVariableFieldsFragment = {
  __typename?: 'SensorTypeVariable';
} & Pick<
  SensorTypeVariable,
  | 'id'
  | 'name'
  | 'address'
  | 'length'
  | 'numericType'
  | 'byteOrder'
  | 'conversionFactor'
  | 'functionCode'
  | 'createdAt'
  | 'updatedAt'
> & {
    valueType?: Maybe<
      { __typename?: 'SensorValueType' } & Pick<
        SensorValueType,
        'id' | 'shortName'
      >
    >;
  };

export type SensorTypesQueryVariables = Exact<{ [key: string]: never }>;

export type SensorTypesQuery = { __typename?: 'Query' } & {
  sensorTypes: Array<
    { __typename?: 'SensorType' } & SensorTypeListFieldsFragment
  >;
};

export type SensorTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SensorTypeQuery = { __typename?: 'Query' } & {
  sensorType: { __typename?: 'SensorType' } & {
    variables: Array<
      { __typename?: 'SensorTypeVariable' } & SensorTypeVariableFieldsFragment
    >;
  } & SensorTypeListFieldsFragment;
};

export type CreateSensorTypeMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateSensorTypeMutation = { __typename?: 'Mutation' } & {
  createSensorType: {
    __typename?: 'SensorType';
  } & SensorTypeListFieldsFragment;
};

export type UpdateSensorTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;

export type UpdateSensorTypeMutation = { __typename?: 'Mutation' } & {
  updateSensorType: {
    __typename?: 'SensorType';
  } & SensorTypeListFieldsFragment;
};

export type DeleteSensorTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSensorTypeMutation = { __typename?: 'Mutation' } & {
  deleteSensorType: {
    __typename?: 'SensorType';
  } & SensorTypeListFieldsFragment;
};

export type SensorFieldsFragment = { __typename?: 'Sensor' } & Pick<
  Sensor,
  'id' | 'name' | 'description' | 'createdAt' | 'updatedAt' | 'online'
> & { agent: { __typename?: 'Agent' } & Pick<Agent, 'online'> };

export type SensorVariableFieldsFragment = {
  __typename?: 'SensorVariable';
} & Pick<SensorVariable, 'id' | 'name'> & {
    valueType?: Maybe<
      { __typename?: 'SensorValueType' } & Pick<
        SensorValueType,
        'name' | 'shortName'
      >
    >;
    overriddenValueType?: Maybe<
      { __typename?: 'SensorValueType' } & Pick<
        SensorValueType,
        'name' | 'shortName'
      >
    >;
    sensorTypeVariable: { __typename?: 'SensorTypeVariable' } & Pick<
      SensorTypeVariable,
      'name'
    >;
    lastMeasure?: Maybe<
      { __typename?: 'Measure' } & Pick<
        Measure,
        'value' | 'dateTime' | 'conversionFactor' | 'hexValue' | 'rawValue'
      >
    >;
  };

export type SensorsQueryVariables = Exact<{
  sensorName: Scalars['String'];
}>;

export type SensorsQuery = { __typename?: 'Query' } & {
  sensorList: { __typename?: 'SensorListQueryResult' } & {
    sensors: Array<{ __typename?: 'Sensor' } & SensorFieldsFragment>;
  };
};

export type SensorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SensorQuery = { __typename?: 'Query' } & {
  sensor: { __typename?: 'Sensor' } & {
    sensorType: { __typename?: 'SensorType' } & Pick<SensorType, 'id' | 'name'>;
    agent: { __typename?: 'Agent' } & Pick<
      Agent,
      'id' | 'name' | 'protocolIdentifier' | 'online'
    >;
    variables: Array<
      { __typename?: 'SensorVariable' } & SensorVariableFieldsFragment
    >;
  } & SensorFieldsFragment;
};

export type AgentSensorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AgentSensorsQuery = { __typename?: 'Query' } & {
  agent: { __typename?: 'Agent' } & Pick<Agent, 'id'> & {
      sensors: Array<
        { __typename?: 'Sensor' } & Pick<
          Sensor,
          'id' | 'online' | 'totalActiveAlarms'
        > & {
            sensorType: { __typename?: 'SensorType' } & Pick<
              SensorType,
              'id' | 'name'
            >;
            variables: Array<
              { __typename?: 'SensorVariable' } & SensorVariableFieldsFragment
            >;
          }
      >;
    };
};

export type UserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email'
>;

export type ClientFieldsFragment = { __typename?: 'Client' } & Pick<
  Client,
  'id' | 'name' | 'alias'
>;

export type SessionFieldsFragment = { __typename?: 'Session' } & Pick<
  Session,
  'role'
> & {
    user: { __typename?: 'User' } & UserFieldsFragment;
    client: { __typename?: 'Client' } & ClientFieldsFragment;
  };

export type UserSessionQueryVariables = Exact<{ [key: string]: never }>;

export type UserSessionQuery = { __typename?: 'Query' } & {
  session: { __typename?: 'Session' } & SessionFieldsFragment;
};

export type UserListQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;

export type UserListQuery = { __typename?: 'Query' } & {
  users: Array<
    { __typename?: 'User' } & {
      assignedClients: Array<
        { __typename?: 'ClientUser' } & Pick<ClientUser, 'id' | 'role'> & {
            client: { __typename?: 'Client' } & ClientFieldsFragment;
          }
      >;
    } & UserFieldsFragment
  >;
};

export type UpdateUserInfoMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
}>;

export type UpdateUserInfoMutation = { __typename?: 'Mutation' } & {
  updateUserInfo: { __typename?: 'User' } & UserFieldsFragment;
};

export type SensorValueTypesFieldsFragment = {
  __typename?: 'SensorValueType';
} & Pick<
  SensorValueType,
  'id' | 'name' | 'shortName' | 'createdAt' | 'updatedAt'
>;

export type SensorValueTypesQueryVariables = Exact<{ [key: string]: never }>;

export type SensorValueTypesQuery = { __typename?: 'Query' } & {
  sensorValueTypes: Array<
    { __typename?: 'SensorValueType' } & SensorValueTypesFieldsFragment
  >;
};

export type CreateSensorValueTypeMutationVariables = Exact<{
  name: Scalars['String'];
  shortName: Scalars['String'];
}>;

export type CreateSensorValueTypeMutation = { __typename?: 'Mutation' } & {
  createSensorValueType: {
    __typename?: 'SensorValueType';
  } & SensorValueTypesFieldsFragment;
};

export type UpdateSensorValueTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
}>;

export type UpdateSensorValueTypeMutation = { __typename?: 'Mutation' } & {
  updateSensorValueType: {
    __typename?: 'SensorValueType';
  } & SensorValueTypesFieldsFragment;
};

export type DeleteSensorValueTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteSensorValueTypeMutation = { __typename?: 'Mutation' } & {
  deleteSensorValueType: {
    __typename?: 'SensorValueType';
  } & SensorValueTypesFieldsFragment;
};

export const AgentFieldsFragmentDoc = gql`
  fragment AgentFields on Agent {
    id
    name
    description
    lastHeartbeat
    externalId
    online
    createdAt
    protocolIdentifier
    latitude
    longitude
    totalActiveAlarms
    totalSensors
  }
`;
export const DashboardFieldsFragmentDoc = gql`
  fragment DashboardFields on Dashboard {
    id
    name
  }
`;
export const ChartVariableFieldsFragmentDoc = gql`
  fragment ChartVariableFields on ChartVariable {
    variable {
      name
    }
    data {
      x
      y
    }
    config {
      color
    }
  }
`;
export const DisplaySettingsFieldsFragmentDoc = gql`
  fragment DisplaySettingsFields on DisplaySettings {
    type
    title
    yAxis {
      title
    }
    xAxis {
      title
    }
  }
`;
export const ChartFieldsFragmentDoc = gql`
  fragment ChartFields on Chart {
    variables {
      ...ChartVariableFields
    }
    displaySettings {
      ...DisplaySettingsFields
    }
  }
  ${ChartVariableFieldsFragmentDoc}
  ${DisplaySettingsFieldsFragmentDoc}
`;
export const HmiFieldsFragmentDoc = gql`
  fragment HMIFields on HMI {
    id
    name
  }
`;
export const InstantChartVariableFieldsFragmentDoc = gql`
  fragment InstantChartVariableFields on InstantChartVariable {
    config {
      name
    }
    value
  }
`;
export const InstantChartFieldsFragmentDoc = gql`
  fragment InstantChartFields on InstantChart {
    variables {
      ...InstantChartVariableFields
    }
    displaySettings {
      ...DisplaySettingsFields
    }
  }
  ${InstantChartVariableFieldsFragmentDoc}
  ${DisplaySettingsFieldsFragmentDoc}
`;
export const DashboardChartFieldsFragmentDoc = gql`
  fragment DashboardChartFields on DashboardChart {
    id
    type
    name
    chart {
      ... on Chart {
        ...ChartFields
      }
      ... on HMI {
        ...HMIFields
      }
      ... on InstantChart {
        ...InstantChartFields
      }
    }
  }
  ${ChartFieldsFragmentDoc}
  ${HmiFieldsFragmentDoc}
  ${InstantChartFieldsFragmentDoc}
`;
export const SensorTypeListFieldsFragmentDoc = gql`
  fragment SensorTypeListFields on SensorType {
    id
    name
    createdAt
    updatedAt
  }
`;
export const SensorTypeVariableFieldsFragmentDoc = gql`
  fragment SensorTypeVariableFields on SensorTypeVariable {
    id
    name
    address
    length
    numericType
    byteOrder
    conversionFactor
    functionCode
    valueType {
      id
      shortName
    }
    createdAt
    updatedAt
  }
`;
export const SensorFieldsFragmentDoc = gql`
  fragment SensorFields on Sensor {
    id
    name
    description
    createdAt
    updatedAt
    agent {
      online
    }
    online
  }
`;
export const SensorVariableFieldsFragmentDoc = gql`
  fragment SensorVariableFields on SensorVariable {
    id
    name
    valueType {
      name
      shortName
    }
    overriddenValueType {
      name
      shortName
    }
    sensorTypeVariable {
      name
    }
    lastMeasure {
      value
      dateTime
      conversionFactor
      hexValue
      rawValue
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
  }
`;
export const ClientFieldsFragmentDoc = gql`
  fragment ClientFields on Client {
    id
    name
    alias
  }
`;
export const SessionFieldsFragmentDoc = gql`
  fragment SessionFields on Session {
    user {
      ...UserFields
    }
    client {
      ...ClientFields
    }
    role
  }
  ${UserFieldsFragmentDoc}
  ${ClientFieldsFragmentDoc}
`;
export const SensorValueTypesFieldsFragmentDoc = gql`
  fragment SensorValueTypesFields on SensorValueType {
    id
    name
    shortName
    createdAt
    updatedAt
  }
`;
export const AgentsDocument = gql`
  query Agents {
    agents {
      ...AgentFields
    }
  }
  ${AgentFieldsFragmentDoc}
`;

/**
 * __useAgentsQuery__
 *
 * To run a query within a React component, call `useAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgentsQuery(
  baseOptions?: Apollo.QueryHookOptions<AgentsQuery, AgentsQueryVariables>
) {
  return Apollo.useQuery<AgentsQuery, AgentsQueryVariables>(
    AgentsDocument,
    baseOptions
  );
}
export function useAgentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgentsQuery, AgentsQueryVariables>
) {
  return Apollo.useLazyQuery<AgentsQuery, AgentsQueryVariables>(
    AgentsDocument,
    baseOptions
  );
}
export type AgentsQueryHookResult = ReturnType<typeof useAgentsQuery>;
export type AgentsLazyQueryHookResult = ReturnType<typeof useAgentsLazyQuery>;
export type AgentsQueryResult = Apollo.QueryResult<
  AgentsQuery,
  AgentsQueryVariables
>;
export const AgentDocument = gql`
  query Agent($agentId: ID!) {
    agent(id: $agentId) {
      ...AgentFields
      protocol
      subProtocol
    }
  }
  ${AgentFieldsFragmentDoc}
`;

/**
 * __useAgentQuery__
 *
 * To run a query within a React component, call `useAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useAgentQuery(
  baseOptions: Apollo.QueryHookOptions<AgentQuery, AgentQueryVariables>
) {
  return Apollo.useQuery<AgentQuery, AgentQueryVariables>(
    AgentDocument,
    baseOptions
  );
}
export function useAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgentQuery, AgentQueryVariables>
) {
  return Apollo.useLazyQuery<AgentQuery, AgentQueryVariables>(
    AgentDocument,
    baseOptions
  );
}
export type AgentQueryHookResult = ReturnType<typeof useAgentQuery>;
export type AgentLazyQueryHookResult = ReturnType<typeof useAgentLazyQuery>;
export type AgentQueryResult = Apollo.QueryResult<
  AgentQuery,
  AgentQueryVariables
>;
export const AgentsListDocument = gql`
  query AgentsList($agentName: String!) {
    agents(filter: { name: $agentName }) {
      ...AgentFields
    }
  }
  ${AgentFieldsFragmentDoc}
`;

/**
 * __useAgentsListQuery__
 *
 * To run a query within a React component, call `useAgentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsListQuery({
 *   variables: {
 *      agentName: // value for 'agentName'
 *   },
 * });
 */
export function useAgentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentsListQuery,
    AgentsListQueryVariables
  >
) {
  return Apollo.useQuery<AgentsListQuery, AgentsListQueryVariables>(
    AgentsListDocument,
    baseOptions
  );
}
export function useAgentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentsListQuery,
    AgentsListQueryVariables
  >
) {
  return Apollo.useLazyQuery<AgentsListQuery, AgentsListQueryVariables>(
    AgentsListDocument,
    baseOptions
  );
}
export type AgentsListQueryHookResult = ReturnType<typeof useAgentsListQuery>;
export type AgentsListLazyQueryHookResult = ReturnType<
  typeof useAgentsListLazyQuery
>;
export type AgentsListQueryResult = Apollo.QueryResult<
  AgentsListQuery,
  AgentsListQueryVariables
>;
export const AgentsWithSensorsDocument = gql`
  query AgentsWithSensors($agentName: String!) {
    agents(filter: { name: $agentName }) {
      ...AgentFields
      sensors {
        id
        online
      }
    }
  }
  ${AgentFieldsFragmentDoc}
`;

/**
 * __useAgentsWithSensorsQuery__
 *
 * To run a query within a React component, call `useAgentsWithSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsWithSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsWithSensorsQuery({
 *   variables: {
 *      agentName: // value for 'agentName'
 *   },
 * });
 */
export function useAgentsWithSensorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentsWithSensorsQuery,
    AgentsWithSensorsQueryVariables
  >
) {
  return Apollo.useQuery<
    AgentsWithSensorsQuery,
    AgentsWithSensorsQueryVariables
  >(AgentsWithSensorsDocument, baseOptions);
}
export function useAgentsWithSensorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentsWithSensorsQuery,
    AgentsWithSensorsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AgentsWithSensorsQuery,
    AgentsWithSensorsQueryVariables
  >(AgentsWithSensorsDocument, baseOptions);
}
export type AgentsWithSensorsQueryHookResult = ReturnType<
  typeof useAgentsWithSensorsQuery
>;
export type AgentsWithSensorsLazyQueryHookResult = ReturnType<
  typeof useAgentsWithSensorsLazyQuery
>;
export type AgentsWithSensorsQueryResult = Apollo.QueryResult<
  AgentsWithSensorsQuery,
  AgentsWithSensorsQueryVariables
>;
export const VariableChartDocument = gql`
  query VariableChart(
    $sensorId: ID!
    $variableId: ID!
    $startDate: Time
    $endDate: Time
  ) {
    sensorVariableChart(
      options: {
        sensorId: $sensorId
        variableId: $variableId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      variables {
        ...ChartVariableFields
      }
      displaySettings {
        ...DisplaySettingsFields
      }
    }
  }
  ${ChartVariableFieldsFragmentDoc}
  ${DisplaySettingsFieldsFragmentDoc}
`;

/**
 * __useVariableChartQuery__
 *
 * To run a query within a React component, call `useVariableChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariableChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariableChartQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      variableId: // value for 'variableId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useVariableChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    VariableChartQuery,
    VariableChartQueryVariables
  >
) {
  return Apollo.useQuery<VariableChartQuery, VariableChartQueryVariables>(
    VariableChartDocument,
    baseOptions
  );
}
export function useVariableChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VariableChartQuery,
    VariableChartQueryVariables
  >
) {
  return Apollo.useLazyQuery<VariableChartQuery, VariableChartQueryVariables>(
    VariableChartDocument,
    baseOptions
  );
}
export type VariableChartQueryHookResult = ReturnType<
  typeof useVariableChartQuery
>;
export type VariableChartLazyQueryHookResult = ReturnType<
  typeof useVariableChartLazyQuery
>;
export type VariableChartQueryResult = Apollo.QueryResult<
  VariableChartQuery,
  VariableChartQueryVariables
>;
export const DashboardDocument = gql`
  query Dashboard {
    dashboards {
      ...DashboardFields
    }
  }
  ${DashboardFieldsFragmentDoc}
`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>
) {
  return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    baseOptions
  );
}
export function useDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardQuery,
    DashboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    baseOptions
  );
}
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<
  typeof useDashboardLazyQuery
>;
export type DashboardQueryResult = Apollo.QueryResult<
  DashboardQuery,
  DashboardQueryVariables
>;
export const DashboardChartListDocument = gql`
  query DashboardChartList($id: ID!) {
    dashboardChartList(id: $id) {
      id
      type
      name
    }
  }
`;

/**
 * __useDashboardChartListQuery__
 *
 * To run a query within a React component, call `useDashboardChartListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardChartListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardChartListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDashboardChartListQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardChartListQuery,
    DashboardChartListQueryVariables
  >
) {
  return Apollo.useQuery<
    DashboardChartListQuery,
    DashboardChartListQueryVariables
  >(DashboardChartListDocument, baseOptions);
}
export function useDashboardChartListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardChartListQuery,
    DashboardChartListQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    DashboardChartListQuery,
    DashboardChartListQueryVariables
  >(DashboardChartListDocument, baseOptions);
}
export type DashboardChartListQueryHookResult = ReturnType<
  typeof useDashboardChartListQuery
>;
export type DashboardChartListLazyQueryHookResult = ReturnType<
  typeof useDashboardChartListLazyQuery
>;
export type DashboardChartListQueryResult = Apollo.QueryResult<
  DashboardChartListQuery,
  DashboardChartListQueryVariables
>;
export const DashboardChartDocument = gql`
  query DashboardChart($id: ID!, $filter: ChartFilter) {
    dashboardChart(id: $id) {
      id
      type
      name
      chart(filter: $filter) {
        ... on Chart {
          ...ChartFields
        }
        ... on HMI {
          ...HMIFields
        }
        ... on InstantChart {
          ...InstantChartFields
        }
      }
    }
  }
  ${ChartFieldsFragmentDoc}
  ${HmiFieldsFragmentDoc}
  ${InstantChartFieldsFragmentDoc}
`;

/**
 * __useDashboardChartQuery__
 *
 * To run a query within a React component, call `useDashboardChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDashboardChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardChartQuery,
    DashboardChartQueryVariables
  >
) {
  return Apollo.useQuery<DashboardChartQuery, DashboardChartQueryVariables>(
    DashboardChartDocument,
    baseOptions
  );
}
export function useDashboardChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardChartQuery,
    DashboardChartQueryVariables
  >
) {
  return Apollo.useLazyQuery<DashboardChartQuery, DashboardChartQueryVariables>(
    DashboardChartDocument,
    baseOptions
  );
}
export type DashboardChartQueryHookResult = ReturnType<
  typeof useDashboardChartQuery
>;
export type DashboardChartLazyQueryHookResult = ReturnType<
  typeof useDashboardChartLazyQuery
>;
export type DashboardChartQueryResult = Apollo.QueryResult<
  DashboardChartQuery,
  DashboardChartQueryVariables
>;
export const HmiVariablesDocument = gql`
  query HMIVariables($id: Int!) {
    hmiVariables(id: $id) {
      name
      shortName
      top
      left
      value
    }
  }
`;

/**
 * __useHmiVariablesQuery__
 *
 * To run a query within a React component, call `useHmiVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHmiVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHmiVariablesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHmiVariablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    HmiVariablesQuery,
    HmiVariablesQueryVariables
  >
) {
  return Apollo.useQuery<HmiVariablesQuery, HmiVariablesQueryVariables>(
    HmiVariablesDocument,
    baseOptions
  );
}
export function useHmiVariablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HmiVariablesQuery,
    HmiVariablesQueryVariables
  >
) {
  return Apollo.useLazyQuery<HmiVariablesQuery, HmiVariablesQueryVariables>(
    HmiVariablesDocument,
    baseOptions
  );
}
export type HmiVariablesQueryHookResult = ReturnType<
  typeof useHmiVariablesQuery
>;
export type HmiVariablesLazyQueryHookResult = ReturnType<
  typeof useHmiVariablesLazyQuery
>;
export type HmiVariablesQueryResult = Apollo.QueryResult<
  HmiVariablesQuery,
  HmiVariablesQueryVariables
>;
export const HmiGraphDocument = gql`
  query HMIGraph($id: Int!, $subId: Int!, $startDate: Time, $endDate: Time) {
    hmiGraph(id: $id, subId: $subId, startDate: $startDate, endDate: $endDate) {
      name
      data {
        x
        y
      }
    }
  }
`;

/**
 * __useHmiGraphQuery__
 *
 * To run a query within a React component, call `useHmiGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useHmiGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHmiGraphQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subId: // value for 'subId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useHmiGraphQuery(
  baseOptions: Apollo.QueryHookOptions<HmiGraphQuery, HmiGraphQueryVariables>
) {
  return Apollo.useQuery<HmiGraphQuery, HmiGraphQueryVariables>(
    HmiGraphDocument,
    baseOptions
  );
}
export function useHmiGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HmiGraphQuery,
    HmiGraphQueryVariables
  >
) {
  return Apollo.useLazyQuery<HmiGraphQuery, HmiGraphQueryVariables>(
    HmiGraphDocument,
    baseOptions
  );
}
export type HmiGraphQueryHookResult = ReturnType<typeof useHmiGraphQuery>;
export type HmiGraphLazyQueryHookResult = ReturnType<
  typeof useHmiGraphLazyQuery
>;
export type HmiGraphQueryResult = Apollo.QueryResult<
  HmiGraphQuery,
  HmiGraphQueryVariables
>;
export const SensorTypesDocument = gql`
  query SensorTypes {
    sensorTypes {
      ...SensorTypeListFields
    }
  }
  ${SensorTypeListFieldsFragmentDoc}
`;

/**
 * __useSensorTypesQuery__
 *
 * To run a query within a React component, call `useSensorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSensorTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SensorTypesQuery,
    SensorTypesQueryVariables
  >
) {
  return Apollo.useQuery<SensorTypesQuery, SensorTypesQueryVariables>(
    SensorTypesDocument,
    baseOptions
  );
}
export function useSensorTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SensorTypesQuery,
    SensorTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<SensorTypesQuery, SensorTypesQueryVariables>(
    SensorTypesDocument,
    baseOptions
  );
}
export type SensorTypesQueryHookResult = ReturnType<typeof useSensorTypesQuery>;
export type SensorTypesLazyQueryHookResult = ReturnType<
  typeof useSensorTypesLazyQuery
>;
export type SensorTypesQueryResult = Apollo.QueryResult<
  SensorTypesQuery,
  SensorTypesQueryVariables
>;
export const SensorTypeDocument = gql`
  query SensorType($id: ID!) {
    sensorType(id: $id) {
      ...SensorTypeListFields
      variables {
        ...SensorTypeVariableFields
      }
    }
  }
  ${SensorTypeListFieldsFragmentDoc}
  ${SensorTypeVariableFieldsFragmentDoc}
`;

/**
 * __useSensorTypeQuery__
 *
 * To run a query within a React component, call `useSensorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    SensorTypeQuery,
    SensorTypeQueryVariables
  >
) {
  return Apollo.useQuery<SensorTypeQuery, SensorTypeQueryVariables>(
    SensorTypeDocument,
    baseOptions
  );
}
export function useSensorTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SensorTypeQuery,
    SensorTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<SensorTypeQuery, SensorTypeQueryVariables>(
    SensorTypeDocument,
    baseOptions
  );
}
export type SensorTypeQueryHookResult = ReturnType<typeof useSensorTypeQuery>;
export type SensorTypeLazyQueryHookResult = ReturnType<
  typeof useSensorTypeLazyQuery
>;
export type SensorTypeQueryResult = Apollo.QueryResult<
  SensorTypeQuery,
  SensorTypeQueryVariables
>;
export const CreateSensorTypeDocument = gql`
  mutation CreateSensorType($name: String!) {
    createSensorType(input: { name: $name }) {
      ...SensorTypeListFields
    }
  }
  ${SensorTypeListFieldsFragmentDoc}
`;
export type CreateSensorTypeMutationFn = Apollo.MutationFunction<
  CreateSensorTypeMutation,
  CreateSensorTypeMutationVariables
>;

/**
 * __useCreateSensorTypeMutation__
 *
 * To run a mutation, you first call `useCreateSensorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorTypeMutation, { data, loading, error }] = useCreateSensorTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSensorTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSensorTypeMutation,
    CreateSensorTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateSensorTypeMutation,
    CreateSensorTypeMutationVariables
  >(CreateSensorTypeDocument, baseOptions);
}
export type CreateSensorTypeMutationHookResult = ReturnType<
  typeof useCreateSensorTypeMutation
>;
export type CreateSensorTypeMutationResult = Apollo.MutationResult<CreateSensorTypeMutation>;
export type CreateSensorTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateSensorTypeMutation,
  CreateSensorTypeMutationVariables
>;
export const UpdateSensorTypeDocument = gql`
  mutation UpdateSensorType($id: ID!, $name: String!) {
    updateSensorType(id: $id, name: $name) {
      ...SensorTypeListFields
    }
  }
  ${SensorTypeListFieldsFragmentDoc}
`;
export type UpdateSensorTypeMutationFn = Apollo.MutationFunction<
  UpdateSensorTypeMutation,
  UpdateSensorTypeMutationVariables
>;

/**
 * __useUpdateSensorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSensorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorTypeMutation, { data, loading, error }] = useUpdateSensorTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSensorTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSensorTypeMutation,
    UpdateSensorTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSensorTypeMutation,
    UpdateSensorTypeMutationVariables
  >(UpdateSensorTypeDocument, baseOptions);
}
export type UpdateSensorTypeMutationHookResult = ReturnType<
  typeof useUpdateSensorTypeMutation
>;
export type UpdateSensorTypeMutationResult = Apollo.MutationResult<UpdateSensorTypeMutation>;
export type UpdateSensorTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSensorTypeMutation,
  UpdateSensorTypeMutationVariables
>;
export const DeleteSensorTypeDocument = gql`
  mutation DeleteSensorType($id: ID!) {
    deleteSensorType(id: $id) {
      ...SensorTypeListFields
    }
  }
  ${SensorTypeListFieldsFragmentDoc}
`;
export type DeleteSensorTypeMutationFn = Apollo.MutationFunction<
  DeleteSensorTypeMutation,
  DeleteSensorTypeMutationVariables
>;

/**
 * __useDeleteSensorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteSensorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorTypeMutation, { data, loading, error }] = useDeleteSensorTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSensorTypeMutation,
    DeleteSensorTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteSensorTypeMutation,
    DeleteSensorTypeMutationVariables
  >(DeleteSensorTypeDocument, baseOptions);
}
export type DeleteSensorTypeMutationHookResult = ReturnType<
  typeof useDeleteSensorTypeMutation
>;
export type DeleteSensorTypeMutationResult = Apollo.MutationResult<DeleteSensorTypeMutation>;
export type DeleteSensorTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteSensorTypeMutation,
  DeleteSensorTypeMutationVariables
>;
export const SensorsDocument = gql`
  query Sensors($sensorName: String!) {
    sensorList(first: 50, filter: { name: $sensorName }) {
      sensors {
        ...SensorFields
      }
    }
  }
  ${SensorFieldsFragmentDoc}
`;

/**
 * __useSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsQuery({
 *   variables: {
 *      sensorName: // value for 'sensorName'
 *   },
 * });
 */
export function useSensorsQuery(
  baseOptions: Apollo.QueryHookOptions<SensorsQuery, SensorsQueryVariables>
) {
  return Apollo.useQuery<SensorsQuery, SensorsQueryVariables>(
    SensorsDocument,
    baseOptions
  );
}
export function useSensorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SensorsQuery, SensorsQueryVariables>
) {
  return Apollo.useLazyQuery<SensorsQuery, SensorsQueryVariables>(
    SensorsDocument,
    baseOptions
  );
}
export type SensorsQueryHookResult = ReturnType<typeof useSensorsQuery>;
export type SensorsLazyQueryHookResult = ReturnType<typeof useSensorsLazyQuery>;
export type SensorsQueryResult = Apollo.QueryResult<
  SensorsQuery,
  SensorsQueryVariables
>;
export const SensorDocument = gql`
  query Sensor($id: ID!) {
    sensor(id: $id) {
      ...SensorFields
      sensorType {
        id
        name
      }
      agent {
        id
        name
        protocolIdentifier
        online
      }
      variables {
        ...SensorVariableFields
      }
    }
  }
  ${SensorFieldsFragmentDoc}
  ${SensorVariableFieldsFragmentDoc}
`;

/**
 * __useSensorQuery__
 *
 * To run a query within a React component, call `useSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorQuery(
  baseOptions: Apollo.QueryHookOptions<SensorQuery, SensorQueryVariables>
) {
  return Apollo.useQuery<SensorQuery, SensorQueryVariables>(
    SensorDocument,
    baseOptions
  );
}
export function useSensorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SensorQuery, SensorQueryVariables>
) {
  return Apollo.useLazyQuery<SensorQuery, SensorQueryVariables>(
    SensorDocument,
    baseOptions
  );
}
export type SensorQueryHookResult = ReturnType<typeof useSensorQuery>;
export type SensorLazyQueryHookResult = ReturnType<typeof useSensorLazyQuery>;
export type SensorQueryResult = Apollo.QueryResult<
  SensorQuery,
  SensorQueryVariables
>;
export const AgentSensorsDocument = gql`
  query AgentSensors($id: ID!) {
    agent(id: $id) {
      id
      sensors {
        id
        online
        totalActiveAlarms
        sensorType {
          id
          name
        }
        variables {
          ...SensorVariableFields
        }
      }
    }
  }
  ${SensorVariableFieldsFragmentDoc}
`;

/**
 * __useAgentSensorsQuery__
 *
 * To run a query within a React component, call `useAgentSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentSensorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgentSensorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgentSensorsQuery,
    AgentSensorsQueryVariables
  >
) {
  return Apollo.useQuery<AgentSensorsQuery, AgentSensorsQueryVariables>(
    AgentSensorsDocument,
    baseOptions
  );
}
export function useAgentSensorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgentSensorsQuery,
    AgentSensorsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AgentSensorsQuery, AgentSensorsQueryVariables>(
    AgentSensorsDocument,
    baseOptions
  );
}
export type AgentSensorsQueryHookResult = ReturnType<
  typeof useAgentSensorsQuery
>;
export type AgentSensorsLazyQueryHookResult = ReturnType<
  typeof useAgentSensorsLazyQuery
>;
export type AgentSensorsQueryResult = Apollo.QueryResult<
  AgentSensorsQuery,
  AgentSensorsQueryVariables
>;
export const UserSessionDocument = gql`
  query UserSession {
    session {
      ...SessionFields
    }
  }
  ${SessionFieldsFragmentDoc}
`;

/**
 * __useUserSessionQuery__
 *
 * To run a query within a React component, call `useUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSessionQuery,
    UserSessionQueryVariables
  >
) {
  return Apollo.useQuery<UserSessionQuery, UserSessionQueryVariables>(
    UserSessionDocument,
    baseOptions
  );
}
export function useUserSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSessionQuery,
    UserSessionQueryVariables
  >
) {
  return Apollo.useLazyQuery<UserSessionQuery, UserSessionQueryVariables>(
    UserSessionDocument,
    baseOptions
  );
}
export type UserSessionQueryHookResult = ReturnType<typeof useUserSessionQuery>;
export type UserSessionLazyQueryHookResult = ReturnType<
  typeof useUserSessionLazyQuery
>;
export type UserSessionQueryResult = Apollo.QueryResult<
  UserSessionQuery,
  UserSessionQueryVariables
>;
export const UserListDocument = gql`
  query UserList($searchText: String!) {
    users(filter: { searchText: $searchText }) {
      ...UserFields
      assignedClients {
        id
        role
        client {
          ...ClientFields
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${ClientFieldsFragmentDoc}
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  return Apollo.useQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    baseOptions
  );
}
export function useUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserListQuery,
    UserListQueryVariables
  >
) {
  return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    baseOptions
  );
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<
  typeof useUserListLazyQuery
>;
export type UserListQueryResult = Apollo.QueryResult<
  UserListQuery,
  UserListQueryVariables
>;
export const UpdateUserInfoDocument = gql`
  mutation UpdateUserInfo(
    $firstName: String!
    $lastName: String!
    $password: String
  ) {
    updateUserInfo(
      input: { firstName: $firstName, lastName: $lastName, password: $password }
    ) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >(UpdateUserInfoDocument, baseOptions);
}
export type UpdateUserInfoMutationHookResult = ReturnType<
  typeof useUpdateUserInfoMutation
>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;
export const SensorValueTypesDocument = gql`
  query SensorValueTypes {
    sensorValueTypes {
      ...SensorValueTypesFields
    }
  }
  ${SensorValueTypesFieldsFragmentDoc}
`;

/**
 * __useSensorValueTypesQuery__
 *
 * To run a query within a React component, call `useSensorValueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorValueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorValueTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSensorValueTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SensorValueTypesQuery,
    SensorValueTypesQueryVariables
  >
) {
  return Apollo.useQuery<SensorValueTypesQuery, SensorValueTypesQueryVariables>(
    SensorValueTypesDocument,
    baseOptions
  );
}
export function useSensorValueTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SensorValueTypesQuery,
    SensorValueTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SensorValueTypesQuery,
    SensorValueTypesQueryVariables
  >(SensorValueTypesDocument, baseOptions);
}
export type SensorValueTypesQueryHookResult = ReturnType<
  typeof useSensorValueTypesQuery
>;
export type SensorValueTypesLazyQueryHookResult = ReturnType<
  typeof useSensorValueTypesLazyQuery
>;
export type SensorValueTypesQueryResult = Apollo.QueryResult<
  SensorValueTypesQuery,
  SensorValueTypesQueryVariables
>;
export const CreateSensorValueTypeDocument = gql`
  mutation CreateSensorValueType($name: String!, $shortName: String!) {
    createSensorValueType(input: { name: $name, shortName: $shortName }) {
      ...SensorValueTypesFields
    }
  }
  ${SensorValueTypesFieldsFragmentDoc}
`;
export type CreateSensorValueTypeMutationFn = Apollo.MutationFunction<
  CreateSensorValueTypeMutation,
  CreateSensorValueTypeMutationVariables
>;

/**
 * __useCreateSensorValueTypeMutation__
 *
 * To run a mutation, you first call `useCreateSensorValueTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorValueTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorValueTypeMutation, { data, loading, error }] = useCreateSensorValueTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *   },
 * });
 */
export function useCreateSensorValueTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSensorValueTypeMutation,
    CreateSensorValueTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateSensorValueTypeMutation,
    CreateSensorValueTypeMutationVariables
  >(CreateSensorValueTypeDocument, baseOptions);
}
export type CreateSensorValueTypeMutationHookResult = ReturnType<
  typeof useCreateSensorValueTypeMutation
>;
export type CreateSensorValueTypeMutationResult = Apollo.MutationResult<CreateSensorValueTypeMutation>;
export type CreateSensorValueTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateSensorValueTypeMutation,
  CreateSensorValueTypeMutationVariables
>;
export const UpdateSensorValueTypeDocument = gql`
  mutation UpdateSensorValueType(
    $id: ID!
    $name: String!
    $shortName: String!
  ) {
    updateSensorValueType(id: $id, name: $name, shortName: $shortName) {
      ...SensorValueTypesFields
    }
  }
  ${SensorValueTypesFieldsFragmentDoc}
`;
export type UpdateSensorValueTypeMutationFn = Apollo.MutationFunction<
  UpdateSensorValueTypeMutation,
  UpdateSensorValueTypeMutationVariables
>;

/**
 * __useUpdateSensorValueTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSensorValueTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorValueTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorValueTypeMutation, { data, loading, error }] = useUpdateSensorValueTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *   },
 * });
 */
export function useUpdateSensorValueTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSensorValueTypeMutation,
    UpdateSensorValueTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSensorValueTypeMutation,
    UpdateSensorValueTypeMutationVariables
  >(UpdateSensorValueTypeDocument, baseOptions);
}
export type UpdateSensorValueTypeMutationHookResult = ReturnType<
  typeof useUpdateSensorValueTypeMutation
>;
export type UpdateSensorValueTypeMutationResult = Apollo.MutationResult<UpdateSensorValueTypeMutation>;
export type UpdateSensorValueTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSensorValueTypeMutation,
  UpdateSensorValueTypeMutationVariables
>;
export const DeleteSensorValueTypeDocument = gql`
  mutation DeleteSensorValueType($id: ID!) {
    deleteSensorValueType(id: $id) {
      ...SensorValueTypesFields
    }
  }
  ${SensorValueTypesFieldsFragmentDoc}
`;
export type DeleteSensorValueTypeMutationFn = Apollo.MutationFunction<
  DeleteSensorValueTypeMutation,
  DeleteSensorValueTypeMutationVariables
>;

/**
 * __useDeleteSensorValueTypeMutation__
 *
 * To run a mutation, you first call `useDeleteSensorValueTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorValueTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorValueTypeMutation, { data, loading, error }] = useDeleteSensorValueTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorValueTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSensorValueTypeMutation,
    DeleteSensorValueTypeMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteSensorValueTypeMutation,
    DeleteSensorValueTypeMutationVariables
  >(DeleteSensorValueTypeDocument, baseOptions);
}
export type DeleteSensorValueTypeMutationHookResult = ReturnType<
  typeof useDeleteSensorValueTypeMutation
>;
export type DeleteSensorValueTypeMutationResult = Apollo.MutationResult<DeleteSensorValueTypeMutation>;
export type DeleteSensorValueTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteSensorValueTypeMutation,
  DeleteSensorValueTypeMutationVariables
>;
